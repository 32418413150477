<template>
  <div>
    <div>
      <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 10 }">
        <a-form-model-item prop="team_number" :label="$t('公司')">
          <a-input size="large" v-model="form.team_number" @pressEnter="login" />
        </a-form-model-item>
        <a-form-model-item prop="client_number" :label="$t('货主编号')">
          <a-input size="large" v-model="form.client_number" @pressEnter="login" />
        </a-form-model-item>
        <a-form-model-item prop="password" :label="$t('密码')">
          <a-input-password size="large" v-model="form.password" @pressEnter="login" />
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-row>
      <a-col :span="10" offset="7">
        <a-button type="primary" size="large" :loading="isLoading" style="width: 100%" @click="login">{{
          $t("登录")
        }}</a-button>
      </a-col>
    </a-row>

    <div style="text-align: center; width: 100%; margin-top: 24px">
      <div>{{ $t("试用，购买或问题咨询请扫描下方客户经理二维码") }}</div>
      <div>
        <img :src="wechatCustomerService" width="100" style="margin-top: 8px" />
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/api/system";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      wechatCustomerService: require("@/assets/wechat_customer_service.png"),
      isLoading: false,
      form: {
        team_number: "",
        client_number: "",
        password: "",
      },
      rules: {
        team_number: [{ required: true, message: this.$t("请输入公司编号"), trigger: "change" }],
        client_number: [{ required: true, message: this.$t("请输入货主编号"), trigger: "change" }],
        password: [{ required: true, message: this.$t("请输入密码"), trigger: "change" }],
      },
    };
  },
  methods: {
    login() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          getToken(this.form)
            .then((data) => {
              Cookies.set("access", data.access);
              Cookies.set("refresh", data.refresh);
              this.$router.push("/");
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>
